<template>
  <div>
    <v-layout wrap justify-center v-if="storage">
      <v-flex xs12>
        <v-img
          :src="mediaURL + storage.bannerImage"
          :lazy-src="mediaURL + storage.bannerImage"
          :alt="storage.bannerImage"
          eager
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap fill-height align-content-center>
            <v-flex xs12 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span
                    style="
                      font-family: poppinsbold;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                    }"
                    >Film Safety Director Training Course : <br />
                    Elevating Standards, Empowering Professionals</span
                  >
                </v-flex>
                <v-flex xs12 sm8 md7 pt-5>
                  <span
                    style="
                      font-family: poppinslight;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                    }"
                    v-html="storage.description"
                  >
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 md10 py-5 v-if="storage.generalDetails">
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md5 py-10>
            <v-img
              :src="mediaURL + storage.generalDetails.image"
              :lazy-src="mediaURL + storage.generalDetails.image"
              :alt="storage.generalDetails.image"
              eager
              ><template v-slot:placeholder>
                <ImageLoader /> </template
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md7 pa-2 align-self-center text-left>
            <v-layout wrap pa-5>
              <!-- <v-flex xs12 sm12 md12 text-left>
                <span
                  style="
                    font-family: poppinsbold;
                    color: white;
                    font-size: 24px;
                    letter-spacing: 1px;
                  "
                >
                  {{ storage.bgfSafetyConsultingDetails.generalDetails.title }}
                </span></v-flex> -->
              <v-flex xs12 pt-4 text-left>
                <span
                  style="
                    font-family: poppinsregular;
                    color: black;
                    letter-spacing: 1px;
                    text-align: justify;
                  "
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }"
                  v-html="storage.generalDetails.content"
                ></span> </v-flex
            ></v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center style="background-color: #f9f9f9">
      <v-flex xs12 sm11 md10 py-16>
        <v-layout wrap justify-start>
          <v-flex xs12 sm10 md7 text-left>
            <span
              style="
                font-family: poppinsbold;
                color: #000000;
                letter-spacing: 1px;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
              }"
            >
              The Film Safety Director course is offered in two versions :
            </span></v-flex
          >
        </v-layout>
        <v-layout wrap justify-center py-5>
          <v-flex xs12 sm6 md4 pa-6 v-for="(item, i) in courses" :key="i">
            <v-card elevation="20">
              <v-layout wrap>
                <v-flex xs12>
                  <v-img
                    :src="mediaURL + item.frontImage"
                    :lazy-src="mediaURL + item.frontImage"
                    :alt="item.frontImage"
                    eager
                    ><template v-slot:placeholder>
                      <ImageLoader /> </template></v-img
                ></v-flex>
                <v-flex xs12 px-5 py-5 text-left>
                  <span
                    style="
                      color: #3cb043;
                      font-family: poppinsmedium;
                      font-size: 20px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                    }"
                    >US ${{ item.offerPrice }} &nbsp;</span
                  >
                  <span
                    style="
                      color: #7f7f7f;
                      font-family: poppinslight;
                      font-size: 12px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '14px' : '12px',
                    }"
                    >(Inclusive of all Taxes)</span
                  >
                </v-flex>
                <v-flex xs12 px-5 text-left>
                  <span
                    style="
                      font-family: poppinsbold;
                      color: black;
                      font-size: 18px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '22px' : '18px',
                    }"
                    >{{ item.courseName }}</span
                  >
                </v-flex>
                <v-flex xs12 text-left px-5 pt-2 pb-4>
                  <span
                    style="
                      font-family: poppinslight;
                      color: black;
                      font-size: 14px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }"
                    v-html="item.description"
                  ></span>
                </v-flex>
                <v-flex xs12 text-right px-5 pt-2 pb-10>
                  <v-btn
                    dark
                    small
                    color="#3CB043"
                    :ripple="false"
                    :to="'/courseDetails?id=' + item._id"
                    ><span
                      style="
                        font-family: opensanssemibold;
                        text-transform: none;
                      "
                      >Details</span
                    ></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage", "courses"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      items: [
        {
          img: require("./../../assets/images/fstCourse1.png"),
          rate: "$150",
          name: "FSDT : Elevating Standards, Empowering Professionals",
          description:
            "Assess the effectiveness of an organization's Environmental Management System",
        },
        {
          img: require("./../../assets/images/fstCourse2.png"),
          rate: "$150",
          name: "Environmental Safety Management Systems",
          description:
            "Assess the effectiveness of an organization's Environmental Management System",
        },
      ],
    };
  },
};
</script>