var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.storage)?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bannerImage,"lazy-src":_vm.mediaURL + _vm.storage.bannerImage,"alt":_vm.storage.bannerImage,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","align-content-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"white","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                  })},[_vm._v("Film Safety Director Training Course : "),_c('br'),_vm._v(" Elevating Standards, Empowering Professionals")])]),_c('v-flex',{attrs:{"xs12":"","sm8":"","md7":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"poppinslight","color":"white","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                  }),domProps:{"innerHTML":_vm._s(_vm.storage.description)}})])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[(_vm.storage.generalDetails)?_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":"","py-5":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md5":"","py-10":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.generalDetails.image,"lazy-src":_vm.mediaURL + _vm.storage.generalDetails.image,"alt":_vm.storage.generalDetails.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md7":"","pa-2":"","align-self-center":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black","letter-spacing":"1px","text-align":"justify"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                }),domProps:{"innerHTML":_vm._s(_vm.storage.generalDetails.content)}})])],1)],1)],1)],1):_vm._e()],1),_c('v-layout',{staticStyle:{"background-color":"#f9f9f9"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":"","py-16":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","md7":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","letter-spacing":"1px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
            })},[_vm._v(" The Film Safety Director course is offered in two versions : ")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-5":""}},_vm._l((_vm.courses),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","pa-6":""}},[_c('v-card',{attrs:{"elevation":"20"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + item.frontImage,"lazy-src":_vm.mediaURL + item.frontImage,"alt":item.frontImage,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)})],1),_c('v-flex',{attrs:{"xs12":"","px-5":"","py-5":"","text-left":""}},[_c('span',{staticStyle:{"color":"#3cb043","font-family":"poppinsmedium","font-size":"20px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                  })},[_vm._v("US $"+_vm._s(item.offerPrice)+"  ")]),_c('span',{staticStyle:{"color":"#7f7f7f","font-family":"poppinslight","font-size":"12px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '14px' : '12px',
                  })},[_vm._v("(Inclusive of all Taxes)")])]),_c('v-flex',{attrs:{"xs12":"","px-5":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"black","font-size":"18px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '22px' : '18px',
                  })},[_vm._v(_vm._s(item.courseName))])]),_c('v-flex',{attrs:{"xs12":"","text-left":"","px-5":"","pt-2":"","pb-4":""}},[_c('span',{staticStyle:{"font-family":"poppinslight","color":"black","font-size":"14px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }),domProps:{"innerHTML":_vm._s(item.description)}})]),_c('v-flex',{attrs:{"xs12":"","text-right":"","px-5":"","pt-2":"","pb-10":""}},[_c('v-btn',{attrs:{"dark":"","small":"","color":"#3CB043","ripple":false,"to":'/courseDetails?id=' + item._id}},[_c('span',{staticStyle:{"font-family":"opensanssemibold","text-transform":"none"}},[_vm._v("Details")])])],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }