var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.storage)?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bannerImage,"lazy-src":_vm.mediaURL + _vm.storage.bannerImage,"alt":_vm.storage.bannerImage,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","align-content-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"white","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                  })},[_vm._v("Film Safety Consulting: "),_c('br'),_vm._v(" Everything, Everywhere, All The Time.")])]),_c('v-flex',{attrs:{"xs12":"","sm8":"","md7":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"poppinslight","color":"white","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                  }),domProps:{"innerHTML":_vm._s(_vm.storage.description)}})])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[(_vm.storage.generalDetails)?_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":"","py-5":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md5":"","py-10":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.generalDetails.image,"lazy-src":_vm.mediaURL + _vm.storage.generalDetails.image,"alt":_vm.storage.generalDetails.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md7":"","pa-2":"","pl-5":"","align-self-center":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black","font-size":"14px","letter-spacing":"1px","text-align":"justify"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                }),domProps:{"innerHTML":_vm._s(_vm.storage.generalDetails.content)}})])],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","pb-4":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black","letter-spacing":"1px","text-align":"justify"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                }),domProps:{"innerHTML":_vm._s(_vm.storage.generalDetails.bottomContent)}})])],1)],1):_vm._e()],1),_c('v-layout',{staticStyle:{"background-color":"#3cb043"},attrs:{"wrap":"","pt-10":"","pb-10":"","justify-center":""}},[(_vm.slider.length>0)?_c('v-flex',{attrs:{"xs12":""}},[_c('OwlCarousel',{attrs:{"nav":false,"dots":false,"responsive":_vm.owlResponsive1}},[_vm._l((_vm.slider),function(list,l){return [(list)?_c('v-layout',{key:l,attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"px-1":"","px-sm-3":""}},[_c('router-link',{attrs:{"to":"/galleryFilmSafetyConsulting"}},[_c('img',{staticStyle:{"object-fit":"cover","cursor":"pointer"},attrs:{"src":_vm.mediaURL + list.image,"eager":"","height":"300px","width":"300px","lazy-src":_vm.mediaURL + list.image}})])],1)],1):_vm._e()]})],2)],1):_vm._e()],1),_c('v-layout',{attrs:{"wrap":"","pt-10":"","pb-10":"","justify-center":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }