<template>
  <div>
    <v-layout wrap justify-center v-if="storage">
      <v-flex xs12>
        <v-img :src="mediaURL + storage.bannerImage" :lazy-src="mediaURL + storage.bannerImage" :alt="storage.bannerImage"
          eager>
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap fill-height align-content-center>
            <v-flex xs12 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                      font-family: poppinsbold;
                      color: white;
                      letter-spacing: 1px;
                    " :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                    }">Film Safety Consulting: <br />
                    Everything, Everywhere, All The Time.</span>
                </v-flex>
                <v-flex xs12 sm8 md7 pt-5>
                  <span style="
                      font-family: poppinslight;
                      color: white;
                      letter-spacing: 1px;
                    " :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                    }" v-html="storage.description">
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 md10 py-5 v-if="storage.generalDetails">
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md5 py-10>
            <v-img :src="mediaURL + storage.generalDetails.image" :lazy-src="mediaURL + storage.generalDetails.image"
              :alt="storage.generalDetails.image" eager><template v-slot:placeholder>
                <ImageLoader />
              </template></v-img>
          </v-flex>
          <v-flex xs12 sm12 md7 pa-2 pl-5 align-self-center text-left>
            <v-layout wrap pa-5>
              <v-flex xs12 pt-4 text-left>
                <span style="
                    font-family: poppinsregular;
                    color: black;
                    font-size: 14px;
                    letter-spacing: 1px;
                    text-align: justify;
                  " :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }" v-html="storage.generalDetails.content"></span> </v-flex></v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pb-4>
          <v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinsregular;
                    color: black;
                    letter-spacing: 1px;
                    text-align: justify;
                  "
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }"
                  v-html="storage.generalDetails.bottomContent"
                ></span> </v-flex
            >
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-10 pb-10 justify-center style="background-color: #3cb043">
      <v-flex xs12 v-if="slider.length>0">
        <!-- <viewer :images="slider" :key="slider.length"> -->
          <OwlCarousel :nav="false" :dots="false" :responsive="owlResponsive1">
            <template xs12 sm10 v-for="(list, l) in slider">
              <v-layout wrap justify-center v-if="list" :key="l" >
                <v-flex px-1 px-sm-3>
                  <router-link to="/galleryFilmSafetyConsulting">
                  <img :src="mediaURL + list.image" eager  height="300px"
                      width="300px"
                :lazy-src="mediaURL + list.image" style="object-fit: cover; cursor: pointer" />
                </router-link>
                </v-flex>
              </v-layout>
            </template>
          </OwlCarousel>
        <!-- </viewer> -->
      </v-flex>
    </v-layout>
    <v-layout wrap pt-10 pb-10 justify-center>
      <!-- <viewer :images="slider" :key="slider.length"> -->
      <!-- <v-layout wrap> -->
      <!-- <v-flex xs12>
        <viewer :images="slider" :key="slider.length">
          <OwlCarousel :nav="false" :dots="false" :responsive="owlResponsive">
            <template xs12 sm10 v-for="(list, l) in slider">
              <v-layout wrap justify-center v-if="list" :key="l" >
                <v-flex px-1 px-sm-3>
                  <img :src="mediaURL + list.image" eager
                :lazy-src="mediaURL + list.image" style="object-fit: cover; cursor: pointer" />
                </v-flex>
              </v-layout>
            </template>
          </OwlCarousel>
        </viewer>
      </v-flex> -->
      <!-- </v-layout> -->
      <!-- </viewer> -->
      <!-- <v-flex xs12 align-self-end text-right pt-5>
        <v-btn text to="/galleryFilmSafetyConsulting">
          <span style="font-family: opensanssemibold; text-transform: none"
            >View More</span
          >
        </v-btn>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showMore: false,
      limit: 10,
      pages: 0,
      page: 1,
      currentPage: 1,
      totalData: 0,
      totalPage: 0,
      owlResponsive1: {
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 6, nav: false },
      },
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 6, nav: false },
      },
      items: [
        require("./../../assets/images/catslid1.png"),
        require("./../../assets/images/card1.png"),
      ],
      cards: [
        require("./../../assets/images/card1.png"),
        require("./../../assets/images/card2.png"),
        require("./../../assets/images/card3.png"),
        require("./../../assets/images/card4.png"),
        require("./../../assets/images/catslid1.png"),
      ],
      slider: [],
    };
  },
  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/category/slider/list",
        method: "POST",
        data: {
          limit: 10,
          page: this.page,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.slider =response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
          this.totalPage = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    handleCarouselDrag(event) {
      this.page++;
        if (this.page <= this.totalPage) this.getData();
        console.log('Carousel drag event:', event);
    },
    getList(){
      // this.appLoading = true;
      axios({
        url: "/category/slider/list",
        method: "POST",
        data: {
          page: this.page,
          limit: 10,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.slider = this.slider.concat(response.data.data);
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>