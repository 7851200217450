<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap v-if="$route.query.id == '64a4ead91c152d17d98ef0f2'">
      <v-flex xs12>
        <IndustrySafetyConsulting :storage="category" />
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="$route.query.id == '64a4ea541c152d17d98ef0eb'">
      <v-flex xs12>
        <FilmSafetyConsulting :storage="category" />
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="$route.query.id == '64a4ea9c1c152d17d98ef0ef'">
      <v-flex xs12>
        <FilmSafetyTraining :storage="category" :courses="courses" />
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="$route.query.id == '64a4eaf51c152d17d98ef0f5'">
      <v-flex xs12>
        <IndustrySafetyTraining :storage="category" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import IndustrySafetyConsulting from "./industrySafetyConsulting";
import FilmSafetyConsulting from "./filmSafetyConsulting";
import FilmSafetyTraining from "./filmSafetyTraining";
import IndustrySafetyTraining from "./industrySafetyTraining";
export default {
  components: {
    IndustrySafetyConsulting,
    FilmSafetyConsulting,
    FilmSafetyTraining,
    IndustrySafetyTraining,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      category: "",
      courses: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/category/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.courses = response.data.courses;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>