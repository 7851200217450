<template>
  <div>
    <v-layout wrap justify-center v-if="storage">
      <v-flex xs12>
        <v-img
          :src="mediaURL + storage.bannerImage"
          :lazy-src="mediaURL + storage.bannerImage"
          :alt="storage.bannerImage"
          eager
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap fill-height align-content-center>
            <v-flex xs12 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span
                    style="
                      font-family: poppinsbold;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                    }"
                    >{{ storage.name }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: poppinsmedium;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                    }"
                  >
                    at
                    <br />
                    BGF Safety Consulting</span
                  >
                </v-flex>
                <v-flex xs12 sm8 md7 pt-5>
                  <span
                    style="
                      font-family: poppinslight;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                    }"
                    v-html="storage.description"
                  >
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-img>
        <v-layout
          wrap
          justify-center
          py-16
          v-if="storage.bgfSafetyConsultingDetails"
        >
          <v-flex
            xs12
            sm11
            md10
            pb-5
            v-if="storage.bgfSafetyConsultingDetails.topContent"
          >
            <v-layout wrap>
              <v-flex xs12 sm12 md12 pa-2 align-self-center text-left>
                <v-layout wrap
                  ><v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        color: #000000;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                      }"
                    >
                      {{ storage.bgfSafetyConsultingDetails.topContent.title }}
                    </span></v-flex
                  ><v-flex xs12 pt-4 text-left>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        letter-spacing: 1px;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyConsultingDetails.topContent.content
                      "
                    ></span> </v-flex
                ></v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-layout
            wrap
            pt-10
            pb-10
            justify-center
            style="background-color: #3cb043"
          >
            <v-flex
              xs12
              sm12
              md12
              v-if="storage.bgfSafetyConsultingDetails.topContent"
            >
              <!-- <viewer
                :images="
                  storage.bgfSafetyConsultingDetails.topContent.sliderImages
                "
                :key="
                  storage.bgfSafetyConsultingDetails.topContent.sliderImages
                "
              > -->
                <!-- :loop="true"
                  :autoplay="true"
                  :autoplayTimeout="3000" -->
                <OwlCarousel
                  :nav="false"
                  :dots="false"
                  :responsive="owlResponsive"
                >
                  <template
                    xs12
                    sm10
                    v-for="(item, i) in storage.bgfSafetyConsultingDetails
                      .topContent.sliderImages"
                  >
                    <v-layout wrap justify-center :key="i">
                      <v-flex px-1 px-sm-3>
                        <router-link :to="'/galleryBiohazardISC?id=' + storage._id ">
                        <v-img
                          :src="mediaURL + item" height="300px"
                          style="object-fit: cover; cursor: pointer"
                        >
                        </v-img>
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </template>
                </OwlCarousel>
              <!-- </viewer> -->
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout
          wrap
          justify-center
          v-if="storage.bgfSafetyConsultingDetails"
          style="background-color: #eefff2"
        >
          <v-flex xs12 sm11 md10>
            <v-layout wrap justify-center py-16>
              <v-flex
                xs12
                sm12
                md5
                pa-2
                v-if="storage.bgfSafetyConsultingDetails.expertsIn"
              >
                <v-img
                  :src="
                    mediaURL +
                    storage.bgfSafetyConsultingDetails.expertsIn.images
                  "
                  :lazy-src="
                    mediaURL +
                    storage.bgfSafetyConsultingDetails.expertsIn.images
                  "
                  :alt="storage.bgfSafetyConsultingDetails.expertsIn.images"
                  eager
                  ><template v-slot:placeholder> <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md7 pa-2 align-self-center text-left>
                <v-layout wrap pa-5
                  ><v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        color: #000000;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                      }"
                    >
                      {{ storage.bgfSafetyConsultingDetails.expertsIn.title }}
                    </span></v-flex
                  ><v-flex xs12 pt-4 text-left>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyConsultingDetails.expertsIn.content
                      "
                    ></span> </v-flex
                ></v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="storage.bgfSafetyConsultingDetails">
          <v-flex
            xs12
            sm11
            md10
            v-if="storage.bgfSafetyConsultingDetails.hazopContent"
          >
            <v-layout wrap justify-center py-16>
              <v-flex xs12 sm12 md7 pa-2 align-self-center text-left>
                <v-layout wrap
                  ><v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        color: #000000;
                        letter-spacing: 1px;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                      }"
                    >
                      {{
                        storage.bgfSafetyConsultingDetails.hazopContent.title
                      }}
                    </span></v-flex
                  ><v-flex xs12 pt-4 text-left>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        letter-spacing: 1px;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyConsultingDetails.hazopContent.content
                      "
                    ></span> </v-flex
                ></v-layout>
              </v-flex>
              <v-flex
                xs12
                sm12
                md5
                px-6
                align-self-center
                v-if="storage.bgfSafetyConsultingDetails.hazopContent"
              >
                <!-- <iframe
                  :key="
                    storage.bgfSafetyConsultingDetails.hazopContent.videoLink
                  "
                  :src="
                    'https://www.youtube.com/embed/' +
                    storage.bgfSafetyConsultingDetails.hazopContent.videoLink +
                    '?rel=0'
                  "
                  width="450px"
                  height="250px"
                  scrolling="no"
                  contain
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                ></iframe> -->
                <v-card elevation="24">
                  <v-layout wrap py-5>
                    <v-flex xs12>
                      <video
                        :width="
                          $vuetify.breakpoint.name == 'xs'
                            ? '400px'
                            : $vuetify.breakpoint.name == 'sm'
                            ? '800px'
                            : $vuetify.breakpoint.name == 'md'
                            ? '300px'
                            : $vuetify.breakpoint.name == 'lg'
                            ? '440px'
                            : '650px'
                        "
                        controls
                      >
                        <source
                          contain
                          src="./../../assets/snake.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          py-16
          justify-center
          v-if="storage.bgfSafetyConsultingDetails"
          style="background-color: #3cb043"
        >
          <v-flex xs12 sm12>
            <!-- <viewer
              :images="storage.bgfSafetyConsultingDetails.slidderImage"
              :key="storage.bgfSafetyConsultingDetails.slidderImage"
            > -->
              <OwlCarousel
                :nav="false"
                :dots="false"
                :responsive="owlResponsive1"
              >
                <template
                  xs12
                  sm10
                  v-for="(item, i) in storage.bgfSafetyConsultingDetails
                    .slidderImage"
                >
                  <v-layout wrap justify-center :key="i">
                    <v-flex px-1 px-sm-3>
                      <router-link :to="'/galleryHAZOPISC?id=' + storage._id ">
                      <img
                      height="300px"
                      width="300px"
                        :src="mediaURL + item"
                        style="object-fit: cover; cursor: pointer"
                      />
                      </router-link>
                    </v-flex>
                  </v-layout>
                </template>
              </OwlCarousel>
            <!-- </viewer> -->
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-center
          v-if="storage.bgfSafetyConsultingDetails"
          pt-16
        >
          <v-flex xs12 style="background-color: #eefff2">
            <!-- <v-img src="./../../assets/images/catsection4.png"> -->
            <v-layout wrap justify-center>
              <v-flex xs12 sm11 md10 py-5>
                <v-layout wrap justify-center>
                  <v-flex
                    xs12
                    sm12
                    md5
                    py-10
                    v-if="
                      storage.bgfSafetyConsultingDetails.comperhensiveDetails
                    "
                  >
                    <v-img
                      :src="
                        mediaURL +
                        storage.bgfSafetyConsultingDetails.comperhensiveDetails
                          .images
                      "
                      :lazy-src="
                        mediaURL +
                        storage.bgfSafetyConsultingDetails.comperhensiveDetails
                          .images
                      "
                      :alt="
                        storage.bgfSafetyConsultingDetails.comperhensiveDetails
                          .images
                      "
                      eager
                      ><template v-slot:placeholder> <ImageLoader /> </template
                    ></v-img>
                  </v-flex>
                  <v-flex xs12 sm12 md7 pa-2 align-self-center text-left>
                    <v-layout wrap pa-5
                      ><v-flex xs12 sm12 md12 text-left>
                        <span
                          style="
                            font-family: poppinsbold;
                            color: black;
                            letter-spacing: 1px;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '28px'
                                : '24px',
                          }"
                        >
                          {{
                            storage.bgfSafetyConsultingDetails
                              .comperhensiveDetails.title
                          }}
                        </span></v-flex
                      ><v-flex xs12 pt-4 text-left>
                        <span
                          style="
                            font-family: poppinsregular;
                            color: black;
                            font-size: 14px;
                            letter-spacing: 1px;
                            text-align: justify;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }"
                          v-html="
                            storage.bgfSafetyConsultingDetails
                              .comperhensiveDetails.content
                          "
                        ></span> </v-flex
                    ></v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- </v-img> -->
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="storage.bgfSafetyConsultingDetails">
          <v-flex xs1 text-left align-self-end class="hidden-sm-and-down" pb-16>
            <v-layout wrap justify-start align-content-end>
              <v-flex xs10 text-left align-self-end>
                <v-img
                  height="150px"
                  width="80px"
                  contain
                  src="./../../assets/images/gp1.png"
                ></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm11 md10 pt-5 pb-10>
            <v-layout wrap justify-center>
              <v-flex xs12 sm12 md7 pa-2 align-self-center text-left>
                <v-layout wrap pa-5
                  ><v-flex xs12 sm12 md12 text-left>
                    <span
                      style="
                        font-family: poppinsbold;
                        color: black;
                        font-size: 24px;
                        letter-spacing: 1px;
                      "
                    >
                      {{
                        storage.bgfSafetyConsultingDetails.bottomContent.title
                      }}
                    </span></v-flex
                  ><v-flex xs12 pt-4 text-left>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: black;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: justify;
                      "
                      v-html="
                        storage.bgfSafetyConsultingDetails.bottomContent.content
                      "
                    ></span> </v-flex
                ></v-layout>
              </v-flex>
              <v-flex
                xs12
                sm12
                md5
                pt-10
                v-if="storage.bgfSafetyConsultingDetails.bottomContent"
              >
                <v-img
                  :src="
                    mediaURL +
                    storage.bgfSafetyConsultingDetails.bottomContent.images
                  "
                  :lazy-src="
                    mediaURL +
                    storage.bgfSafetyConsultingDetails.bottomContent.images
                  "
                  :alt="storage.bgfSafetyConsultingDetails.bottomContent.images"
                  eager
                  ><template v-slot:placeholder> <ImageLoader /> </template
                ></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 6, nav: false },
      },
      owlResponsive1: {
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 6, nav: false },
      },
      items: [
        require("./../../assets/images/catslid1.png"),
        require("./../../assets/images/card1.png"),
      ],
      cards: [
        require("./../../assets/images/card1.png"),
        require("./../../assets/images/card2.png"),
        require("./../../assets/images/card3.png"),
        require("./../../assets/images/card4.png"),
        require("./../../assets/images/catslid1.png"),
      ],
    };
  },
};
</script>
<style>
.Headd {
  position: relative;
}

.Taill {
  position: absolute;
  top: 1730px;
  z-index: 3;
  right: 0;
  left: 650px;
  display: grid;
}

.clash {
  z-index: 1px;
  /* padding-bottom: 250px; */
  position: relative;
}
</style>