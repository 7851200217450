<template>
  <div>
    <v-layout wrap justify-center v-if="storage">
      <v-flex xs12>
        <v-img
          :src="mediaURL + storage.bannerImage"
          :lazy-src="mediaURL + storage.bannerImage"
          :alt="storage.bannerImage"
          eager
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap fill-height align-content-center>
            <v-flex xs12 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span
                    style="
                      font-family: poppinsbold;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                    }"
                    >BGF Safety Consulting : <br />
                    Pioneering Industry Safety Training</span
                  >
                </v-flex>
                <v-flex xs12 sm10 md7 pt-5>
                  <span
                    style="
                      font-family: poppinslight;
                      color: white;
                      letter-spacing: 1px;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                    }"
                    v-html="storage.description"
                  >
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 md10>
        <v-layout wrap justify-center py-10>
          <v-flex xs12 text-left>
            <span
              style="
                font-family: poppinsbold;
                color: #000000;
                letter-spacing: 1px;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
              }"
              >Our Lead Auditor Courses</span
            >
          </v-flex>
          <v-flex xs12 sm12 md6 pt-5 pa-2>
            <v-card elevation="10" rounded="xl">
              <router-link  :to="'/courseDetails?id=' + '64c9d418ba1cfab10d796733'">
              <v-layout wrap justify-center pa-5>
                <v-flex xs10 sm10 md11 text-left px-5>
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >Biorisk Management Systems (ISO 35001)</span
                  >
                </v-flex>
              </v-layout>
              </router-link>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 pt-5 pa-2>
            <v-card elevation="10" rounded="xl">
              <router-link  :to="'/courseDetails?id=' + '64c8c93177b9132d8e08f92b'">
              <v-layout wrap justify-center pa-5>
                <v-flex xs10 sm10 md11 text-left px-5>
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >Environmental Risk Management Systems (ISO 14001)</span
                  >
                </v-flex>
              </v-layout>
              </router-link>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 pt-5 pa-2>
            <v-card elevation="10" rounded="xl">
              <router-link  :to="'/courseDetails?id=' + '64c8c9de77b9132d8e08f96e'">
              <v-layout wrap justify-center pa-5>
                <v-flex xs10 sm10 md11 text-left px-5>
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >Occupational Risk Management Systems (ISO 45001)</span
                  >
                </v-flex>
              </v-layout>
              </router-link>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 pt-5 pa-2>
            <v-card elevation="10" rounded="xl">
              <router-link  :to="'/courseDetails?id=' + '64c8c9fc77b9132d8e08f97a'">
              <v-layout wrap justify-center pa-5>
                <v-flex xs10 sm10 md11 text-left px-5>
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >Quality Risk Management Systems (ISO 9001)</span
                  >
                </v-flex>
              </v-layout>
              </router-link>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 md10>
        <v-layout wrap justify-center py-10>
          <v-flex xs12 text-left>
            <span
              style="
                font-family: poppinsbold;
                color: #000000;
                letter-spacing: 1px;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
              }"
              >Course Benefits</span
            >
          </v-flex>
          <v-flex xs12 sm12 md6 pt-5 pa-2>
            <v-card elevation="20">
              <v-layout
                wrap
                justify-center
                pa-5
                v-if="storage.bgfSafetyTrainingDetails"
              >
                <v-flex xs2 sm2 md1>
                  <v-img src="./../../assets/images/istc1.png"></v-img>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md11
                  text-left
                  px-5
                  v-if="storage.bgfSafetyTrainingDetails.courseOfferingFirst"
                >
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >{{
                      storage.bgfSafetyTrainingDetails.courseOfferingFirst.title
                    }}</span
                  >
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      color: #000000;
                      text-align: justify;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '17px' : '13px',
                    }"
                    v-html="
                      storage.bgfSafetyTrainingDetails.courseOfferingFirst
                        .description
                    "
                  ></span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6 pt-5 pa-2>
            <v-card elevation="20">
              <v-layout
                wrap
                justify-center
                pa-5
                v-if="storage.bgfSafetyTrainingDetails"
              >
                <v-flex xs2 sm2 md1>
                  <v-img src="./../../assets/images/istc2.png"></v-img>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md11
                  text-left
                  px-5
                  v-if="storage.bgfSafetyTrainingDetails.courseOfferingSecond"
                >
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >{{
                      storage.bgfSafetyTrainingDetails.courseOfferingSecond
                        .title
                    }}</span
                  >
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      color: #000000;
                      text-align: justify;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '17px' : '13px',
                    }"
                    v-html="
                      storage.bgfSafetyTrainingDetails.courseOfferingSecond
                        .description
                    "
                  ></span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md8 pt-5 pa-2>
            <v-card elevation="20">
              <v-layout
                wrap
                justify-center
                pa-5
                v-if="storage.bgfSafetyTrainingDetails"
              >
                <v-flex xs2 sm2 md1 pt-5>
                  <v-img
                    height="60px"
                    width="60px"
                    src="./../../assets/images/istc3.png"
                  ></v-img>
                </v-flex>
                <v-flex
                  xs10
                  sm10
                  md11
                  text-left
                  px-4
                  v-if="storage.bgfSafetyTrainingDetails.courseOfferingThird"
                >
                  <span
                    style="color: black; font-family: poppinsmedium"
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                    }"
                    >{{
                      storage.bgfSafetyTrainingDetails.courseOfferingThird.title
                    }}</span
                  >
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      color: #000000;
                      text-align: justify;
                    "
                    :style="{
                      'font-size':
                        $vuetify.breakpoint.name == 'xl' ? '17px' : '13px',
                    }"
                    v-html="
                      storage.bgfSafetyTrainingDetails.courseOfferingThird
                        .description
                    "
                  ></span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center style="background-color: #3cb043">
      <v-flex xs12 sm11 md10>
        <v-layout
          wrap
          justify-center
          py-10
          v-if="storage.bgfSafetyTrainingDetails"
        >
          <v-flex
            xs12
            sm12
            md5
            v-if="storage.bgfSafetyTrainingDetails.topContent"
          >
            <v-img
              :src="
                mediaURL + storage.bgfSafetyTrainingDetails.topContent.image
              "
              :lazy-src="
                mediaURL + storage.bgfSafetyTrainingDetails.topContent.image
              "
              :alt="storage.bgfSafetyTrainingDetails.topContent.image"
              eager
              ><template v-slot:placeholder> <ImageLoader /> </template
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md7 pa-2 align-self-center text-left>
            <v-layout wrap pa-5
              ><v-flex xs12 text-left>
                <span
                  style="
                    font-family: poppinsbold;
                    color: #fff;
                    letter-spacing: 1px;
                  "
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                  }"
                >
                  {{ storage.bgfSafetyTrainingDetails.topContent.title }}
                </span></v-flex
              ><v-flex xs12 pt-4 text-left>
                <span
                  style="
                    font-family: poppinsregular;
                    color: #fff;
                    letter-spacing: 1px;
                    text-align: justify;
                  "
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }"
                  v-html="
                    storage.bgfSafetyTrainingDetails.topContent.description
                  "
                ></span> </v-flex
            ></v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 md10>
        <v-layout
          wrap
          justify-center
          py-16
          v-if="storage.bgfSafetyTrainingDetails"
        >
          <v-flex xs12 sm12 md7 pa-10 align-self-center text-left>
            <v-layout wrap pa-5 v-if="storage.bgfSafetyTrainingDetails"
              ><v-flex xs12 text-left>
                <span
                  style="font-family: poppinsbold; color: #000000"
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                  }"
                >
                  {{ storage.bgfSafetyTrainingDetails.middleContent.title }}
                </span></v-flex
              ><v-flex xs12 pt-4 text-left>
                <span
                  style="
                    font-family: poppinsregular;
                    color: #000000;
                    text-align: justify;
                  "
                  :style="{
                    'font-size':
                      $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                  }"
                  v-html="
                    storage.bgfSafetyTrainingDetails.middleContent.description
                  "
                ></span> </v-flex
            ></v-layout>
          </v-flex>
          <v-flex
            text-right
            xs12
            sm12
            md5
            v-if="storage.bgfSafetyTrainingDetails.middleContent"
          >
            <v-img
              :src="
                mediaURL + storage.bgfSafetyTrainingDetails.middleContent.image
              "
              :lazy-src="
                mediaURL + storage.bgfSafetyTrainingDetails.middleContent.image
              "
              :alt="storage.bgfSafetyTrainingDetails.middleContent.image"
              eager
              ><template v-slot:placeholder> <ImageLoader /> </template
            ></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs1 text-right align-self-end class="hidden-sm-and-down" pl-16>
        <v-layout wrap justify-end align-content-end>
          <v-flex xs10 text-right align-self-end>
            <v-img
              height="150px"
              width="80px"
              contain
              src="./../../assets/images/gp1.png"
            ></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center style="background-color: #fafafa">
      <v-flex xs12 sm11 md10>
        <v-layout
          wrap
          justify-center
          py-10
          v-if="storage.bgfSafetyTrainingDetails"
        >
          <v-flex xs12 text-left pl-5 py-2>
            <span
              style="
                font-family: poppinsbold;
                color: #000000;
                letter-spacing: 1px;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
              }"
            >
              {{ storage.bgfSafetyTrainingDetails.adaptiveTraining.title }}
            </span>
          </v-flex>
          <v-flex
            xs12
            sm12
            md5
            v-if="storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint"
          >
            <v-img
              :src="
                mediaURL +
                storage.bgfSafetyTrainingDetails.adaptiveTraining.image
              "
              :lazy-src="
                mediaURL +
                storage.bgfSafetyTrainingDetails.adaptiveTraining.image
              "
              :alt="storage.bgfSafetyTrainingDetails.adaptiveTraining.image"
              eager
              ><template v-slot:placeholder> <ImageLoader /> </template
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md7 align-self-center text-left>
            <v-layout wrap px-5
              ><v-flex xs2 sm2 md1 pt-2
                ><v-img src="./../../assets/images/isticon1.png"></v-img
              ></v-flex>
              <v-flex xs10 sm10 md11 text-left px-5>
                <v-layout wrap>
                  <v-flex xs12>
                    <span
                      style="font-family: poppinsbold; color: #3cb043"
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                      }"
                    >
                      {{
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .firstPoint.title
                      }}
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .firstPoint.description
                      "
                    ></span>
                  </v-flex>
                </v-layout> </v-flex
            ></v-layout>
            <v-layout wrap px-5 py-4
              ><v-flex xs2 sm2 md1 pt-2
                ><v-img src="./../../assets/images/isticon2.png"></v-img
              ></v-flex>
              <v-flex xs10 sm10 md11 text-left px-5>
                <v-layout wrap>
                  <v-flex xs12>
                    <span
                      style="font-family: poppinsbold; color: #3cb043"
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                      }"
                    >
                      {{
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .secondPoint.title
                      }}
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .secondPoint.description
                      "
                    ></span>
                  </v-flex>
                </v-layout> </v-flex
            ></v-layout>
            <v-layout wrap px-5
              ><v-flex xs2 sm2 md1 pt-2
                ><v-img src="./../../assets/images/isticon3.png"></v-img
              ></v-flex>
              <v-flex xs10 sm10 md11 text-left px-5>
                <v-layout wrap>
                  <v-flex xs12>
                    <span
                      style="font-family: poppinsbold; color: #3cb043"
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                      }"
                    >
                      {{
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .thirdPoint.title
                      }}
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .thirdPoint.description
                      "
                    ></span>
                  </v-flex>
                </v-layout> </v-flex
            ></v-layout>
            <v-layout wrap px-5 py-4
              ><v-flex xs2 sm2 md1 pt-2
                ><v-img src="./../../assets/images/isticon4.png"></v-img
              ></v-flex>
              <v-flex xs10 sm10 md11 text-left px-5>
                <v-layout wrap>
                  <v-flex xs12>
                    <span
                      style="font-family: poppinsbold; color: #3cb043"
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                      }"
                    >
                      {{
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .fourthPoint.title
                      }}
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #000000;
                        font-size: 14px;
                        text-align: justify;
                      "
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      v-html="
                        storage.bgfSafetyTrainingDetails.adaptiveTraining
                          .fourthPoint.description
                      "
                    ></span>
                  </v-flex>
                </v-layout> </v-flex
            ></v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <!-- <v-flex xs1 text-left align-self-end class="hidden-sm-and-down" pb-16>
        <v-layout wrap justify-start align-content-end>
          <v-flex xs10 text-left align-self-end>
            <v-img
              height="130px"
              width="80px"
              contain
              src="./../../assets/images/gp1.png"
            ></v-img>
          </v-flex>
        </v-layout>
      </v-flex> -->
      <v-flex xs11 sm11 md10>
        <v-layout wrap justify-center py-10>
          <v-flex xs12 py-2>
            <span
              style="
                font-family: poppinsbold;
                color: #000000;
                letter-spacing: 1px;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
              }"
            >
              Why Choose Us
            </span>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-layout
              wrap
              justify-center
              v-if="storage.bgfSafetyTrainingDetails"
            >
              <v-flex xs12 sm6 md4 lg4 xl2 pa-2>
                <v-card color="#F9DFFB" height="350px" elevation="10">
                  <v-layout wrap pa-5 justify-center>
                    <v-flex xs5 sm6 md6>
                      <v-img
                        :src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            .icon
                        "
                        :lazy-src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            .icon
                        "
                        :alt="
                          storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            .icon
                        "
                        eager
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center px-5>
                        <v-flex xs12>
                          <span
                            style="font-family: poppinsbold; color: #000000"
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '21px'
                                  : '17px',
                            }"
                          >
                            {{
                              storage.bgfSafetyTrainingDetails.whyChooseUs.first
                                .title
                            }}
                          </span>
                        </v-flex>
                        <v-flex xs12 sm10 pt-2>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              font-size: 14px;
                              text-align: justify;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '18px'
                                  : '14px',
                            }"
                            v-html="
                              storage.bgfSafetyTrainingDetails.whyChooseUs.first
                                .description
                            "
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 xl2 pa-2>
                <v-card color="#E7FBD2" height="350px" elevation="10">
                  <v-layout wrap pa-5 justify-center>
                    <v-flex xs5 sm6 md6>
                      <v-img
                        :src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            .icon
                        "
                        :lazy-src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            .icon
                        "
                        :alt="
                          storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            .icon
                        "
                        eager
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center px-5>
                        <v-flex xs12>
                          <span
                            style="font-family: poppinsbold; color: #000000"
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '21px'
                                  : '17px',
                            }"
                          >
                            {{
                              storage.bgfSafetyTrainingDetails.whyChooseUs
                                .second.title
                            }}
                          </span>
                        </v-flex>
                        <v-flex xs12 sm10 pt-2>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              font-size: 14px;
                              text-align: justify;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '18px'
                                  : '14px',
                            }"
                            v-html="
                              storage.bgfSafetyTrainingDetails.whyChooseUs
                                .second.description
                            "
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 xl2 pa-2>
                <v-card color="#EEB899" height="350px" elevation="10">
                  <v-layout wrap pa-5 justify-center>
                    <v-flex xs5 sm6 md6>
                      <v-img
                        :src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            .icon
                        "
                        :lazy-src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            .icon
                        "
                        :alt="
                          storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            .icon
                        "
                        eager
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center px-5>
                        <v-flex xs12>
                          <span
                            style="font-family: poppinsbold; color: #000000"
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '21px'
                                  : '17px',
                            }"
                          >
                            {{
                              storage.bgfSafetyTrainingDetails.whyChooseUs.third
                                .title
                            }}
                          </span>
                        </v-flex>
                        <v-flex xs12 sm10 pt-2>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              text-align: justify;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '18px'
                                  : '14px',
                            }"
                            v-html="
                              storage.bgfSafetyTrainingDetails.whyChooseUs.third
                                .description
                            "
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 xl2 pa-2>
                <v-card color="#FBD2D2" height="350px" elevation="10">
                  <v-layout wrap pa-5 justify-center>
                    <v-flex xs5 sm6 md6>
                      <v-img
                        :src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            .icon
                        "
                        :lazy-src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            .icon
                        "
                        :alt="
                          storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            .icon
                        "
                        eager
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center px-5>
                        <v-flex xs12>
                          <span
                            style="font-family: poppinsbold; color: #000000"
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '21px'
                                  : '17px',
                            }"
                          >
                            {{
                              storage.bgfSafetyTrainingDetails.whyChooseUs
                                .fourth.title
                            }}
                          </span>
                        </v-flex>
                        <v-flex xs12 sm10 pt-2>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              text-align: justify;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '18px'
                                  : '14px',
                            }"
                            v-html="
                              storage.bgfSafetyTrainingDetails.whyChooseUs
                                .fourth.description
                            "
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 xl2 pa-2>
                <v-card color="#D2F1FB" height="350px" elevation="10">
                  <v-layout wrap pa-5 justify-center>
                    <v-flex xs5 sm6 md6>
                      <v-img
                        :src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            .icon
                        "
                        :lazy-src="
                          mediaURL +
                          storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            .icon
                        "
                        :alt="
                          storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            .icon
                        "
                        eager
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center px-5>
                        <v-flex xs12>
                          <span
                            style="font-family: poppinsbold; color: #000000"
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '21px'
                                  : '17px',
                            }"
                          >
                            {{
                              storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                                .title
                            }}
                          </span>
                        </v-flex>
                        <v-flex xs12 sm10 pt-2>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              text-align: justify;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '18px'
                                  : '14px',
                            }"
                            v-html="
                              storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                                .description
                            "
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      justify-center
      style="background-color: #eefff2"
      v-if="storage.bgfSafetyTrainingDetails"
    >
      <v-flex xs12 sm6 md7 align-self-center text-center>
        <v-layout wrap pa-5 justify-center
          ><v-flex xs12 text-center>
            <span
              style="
                font-family: poppinsbold;
                color: #000000;
                letter-spacing: 1px;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
              }"
            >
              {{ storage.bgfSafetyTrainingDetails.bottomContent.title }}
            </span></v-flex
          ><v-flex xs12 sm10 md8 pt-4 text-center>
            <span
              style="
                font-family: poppinsmedium;
                color: #000000;
                text-align: justify;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
              }"
              v-html="
                storage.bgfSafetyTrainingDetails.bottomContent.description
              "
            ></span>
          </v-flex>
          <v-flex xs12 sm12 md12 py-10>
            <v-btn color="#3CB043" dark :to="'/course?id=' + $route.query.id"
              ><span style="font-family: opensanssemibold; text-transform: none"
                >Enroll Today</span
              ></v-btn
            >
          </v-flex>
          <v-flex xs12 sm10 md8 text-center>
            <span
              style="
                font-family: poppinsregular;
                color: #000000;
                text-align: justify;
              "
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
              }"
              v-html="
                storage.bgfSafetyTrainingDetails.bottomContent.smallDescription
              "
            ></span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        sm6
        md5
        v-if="storage.bgfSafetyTrainingDetails.bottomContent"
      >
        <v-img
          :src="mediaURL + storage.bgfSafetyTrainingDetails.bottomContent.image"
          :lazy-src="
            mediaURL + storage.bgfSafetyTrainingDetails.bottomContent.image
          "
          :alt="storage.bgfSafetyTrainingDetails.bottomContent.image"
          eager
          ><template v-slot:placeholder> <ImageLoader /> </template
        ></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      courses:[],
      items: [
        "Biorisk Management Systems (ISO 35001)",
        "Environmental Risk Management Systems (ISO 14001)",
        "Occupational Risk Management Systems (ISO 45001)",
        "Quality Risk Management Systems (ISO 9001)",
      ],
    };
  },
  beforeMount() {
    this.getCourse();
  },

  methods: {
    getCourse() {
      this.appLoading = true;
      axios({
        url: "/course",
        method: "POST",
        data: {
          category: this.$route.query.id,
          subCategory: '64c8c8c977b9132d8e08f90a',
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.courses = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>