var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.storage)?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bannerImage,"lazy-src":_vm.mediaURL + _vm.storage.bannerImage,"alt":_vm.storage.bannerImage,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","align-content-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"white","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
                  })},[_vm._v("BGF Safety Consulting : "),_c('br'),_vm._v(" Pioneering Industry Safety Training")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","md7":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"poppinslight","color":"white","letter-spacing":"1px"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '15px',
                  }),domProps:{"innerHTML":_vm._s(_vm.storage.description)}})])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","letter-spacing":"1px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
            })},[_vm._v("Our Lead Auditor Courses")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"10","rounded":"xl"}},[_c('router-link',{attrs:{"to":'/courseDetails?id=' + '64c9d418ba1cfab10d796733'}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v("Biorisk Management Systems (ISO 35001)")])])],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"10","rounded":"xl"}},[_c('router-link',{attrs:{"to":'/courseDetails?id=' + '64c8c93177b9132d8e08f92b'}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v("Environmental Risk Management Systems (ISO 14001)")])])],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"10","rounded":"xl"}},[_c('router-link',{attrs:{"to":'/courseDetails?id=' + '64c8c9de77b9132d8e08f96e'}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v("Occupational Risk Management Systems (ISO 45001)")])])],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"10","rounded":"xl"}},[_c('router-link',{attrs:{"to":'/courseDetails?id=' + '64c8c9fc77b9132d8e08f97a'}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v("Quality Risk Management Systems (ISO 9001)")])])],1)],1)],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","letter-spacing":"1px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
            })},[_vm._v("Course Benefits")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"20"}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/istc1.png")}})],1),(_vm.storage.bgfSafetyTrainingDetails.courseOfferingFirst)?_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v(_vm._s(_vm.storage.bgfSafetyTrainingDetails.courseOfferingFirst.title))]),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '17px' : '13px',
                  }),domProps:{"innerHTML":_vm._s(
                    _vm.storage.bgfSafetyTrainingDetails.courseOfferingFirst
                      .description
                  )}})]):_vm._e()],1):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"20"}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/istc2.png")}})],1),(_vm.storage.bgfSafetyTrainingDetails.courseOfferingSecond)?_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v(_vm._s(_vm.storage.bgfSafetyTrainingDetails.courseOfferingSecond .title))]),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '17px' : '13px',
                  }),domProps:{"innerHTML":_vm._s(
                    _vm.storage.bgfSafetyTrainingDetails.courseOfferingSecond
                      .description
                  )}})]):_vm._e()],1):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","pt-5":"","pa-2":""}},[_c('v-card',{attrs:{"elevation":"20"}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":"","pt-5":""}},[_c('v-img',{attrs:{"height":"60px","width":"60px","src":require("./../../assets/images/istc3.png")}})],1),(_vm.storage.bgfSafetyTrainingDetails.courseOfferingThird)?_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-4":""}},[_c('span',{staticStyle:{"color":"black","font-family":"poppinsmedium"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
                  })},[_vm._v(_vm._s(_vm.storage.bgfSafetyTrainingDetails.courseOfferingThird.title))]),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xl' ? '17px' : '13px',
                  }),domProps:{"innerHTML":_vm._s(
                    _vm.storage.bgfSafetyTrainingDetails.courseOfferingThird
                      .description
                  )}})]):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1),_c('v-layout',{staticStyle:{"background-color":"#3cb043"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":""}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[(_vm.storage.bgfSafetyTrainingDetails.topContent)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md5":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.topContent.image,"lazy-src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.topContent.image,"alt":_vm.storage.bgfSafetyTrainingDetails.topContent.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm12":"","md7":"","pa-2":"","align-self-center":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#fff","letter-spacing":"1px"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.topContent.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#fff","letter-spacing":"1px","text-align":"justify"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                }),domProps:{"innerHTML":_vm._s(
                  _vm.storage.bgfSafetyTrainingDetails.topContent.description
                )}})])],1)],1)],1):_vm._e()],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":""}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-16":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md7":"","pa-10":"","align-self-center":"","text-left":""}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
                })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.middleContent.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-4":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                }),domProps:{"innerHTML":_vm._s(
                  _vm.storage.bgfSafetyTrainingDetails.middleContent.description
                )}})])],1):_vm._e()],1),(_vm.storage.bgfSafetyTrainingDetails.middleContent)?_c('v-flex',{attrs:{"text-right":"","xs12":"","sm12":"","md5":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.middleContent.image,"lazy-src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.middleContent.image,"alt":_vm.storage.bgfSafetyTrainingDetails.middleContent.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e()],1):_vm._e()],1),_c('v-flex',{staticClass:"hidden-sm-and-down",attrs:{"xs1":"","text-right":"","align-self-end":"","pl-16":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":"","align-content-end":""}},[_c('v-flex',{attrs:{"xs10":"","text-right":"","align-self-end":""}},[_c('v-img',{attrs:{"height":"150px","width":"80px","contain":"","src":require("./../../assets/images/gp1.png")}})],1)],1)],1)],1),_c('v-layout',{staticStyle:{"background-color":"#fafafa"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":""}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","pl-5":"","py-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","letter-spacing":"1px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
            })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining.title)+" ")])]),(_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md5":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL +
              _vm.storage.bgfSafetyTrainingDetails.adaptiveTraining.image,"lazy-src":_vm.mediaURL +
              _vm.storage.bgfSafetyTrainingDetails.adaptiveTraining.image,"alt":_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm12":"","md7":"","align-self-center":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","px-5":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":"","pt-2":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/isticon1.png")}})],1),_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#3cb043"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                    })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining .firstPoint.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }),domProps:{"innerHTML":_vm._s(
                      _vm.storage.bgfSafetyTrainingDetails.adaptiveTraining
                        .firstPoint.description
                    )}})])],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","px-5":"","py-4":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":"","pt-2":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/isticon2.png")}})],1),_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#3cb043"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                    })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining .secondPoint.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }),domProps:{"innerHTML":_vm._s(
                      _vm.storage.bgfSafetyTrainingDetails.adaptiveTraining
                        .secondPoint.description
                    )}})])],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","px-5":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":"","pt-2":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/isticon3.png")}})],1),_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#3cb043"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                    })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining .thirdPoint.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }),domProps:{"innerHTML":_vm._s(
                      _vm.storage.bgfSafetyTrainingDetails.adaptiveTraining
                        .thirdPoint.description
                    )}})])],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","px-5":"","py-4":""}},[_c('v-flex',{attrs:{"xs2":"","sm2":"","md1":"","pt-2":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/isticon4.png")}})],1),_c('v-flex',{attrs:{"xs10":"","sm10":"","md11":"","text-left":"","px-5":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#3cb043"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '21px' : '17px',
                    })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.adaptiveTraining .fourthPoint.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","text-align":"justify"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }),domProps:{"innerHTML":_vm._s(
                      _vm.storage.bgfSafetyTrainingDetails.adaptiveTraining
                        .fourthPoint.description
                    )}})])],1)],1)],1)],1)],1):_vm._e()],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm11":"","md10":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs12":"","py-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","letter-spacing":"1px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
            })},[_vm._v(" Why Choose Us ")])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg4":"","xl2":"","pa-2":""}},[_c('v-card',{attrs:{"color":"#F9DFFB","height":"350px","elevation":"10"}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","justify-center":""}},[_c('v-flex',{attrs:{"xs5":"","sm6":"","md6":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                          .icon,"lazy-src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                          .icon,"alt":_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                          .icon,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '21px'
                                : '17px',
                          })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first .title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","text-align":"justify"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }),domProps:{"innerHTML":_vm._s(
                            _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                              .description
                          )}})])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg4":"","xl2":"","pa-2":""}},[_c('v-card',{attrs:{"color":"#E7FBD2","height":"350px","elevation":"10"}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","justify-center":""}},[_c('v-flex',{attrs:{"xs5":"","sm6":"","md6":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                          .icon,"lazy-src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                          .icon,"alt":_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                          .icon,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '21px'
                                : '17px',
                          })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs .second.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","font-size":"14px","text-align":"justify"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }),domProps:{"innerHTML":_vm._s(
                            _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                              .second.description
                          )}})])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg4":"","xl2":"","pa-2":""}},[_c('v-card',{attrs:{"color":"#EEB899","height":"350px","elevation":"10"}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","justify-center":""}},[_c('v-flex',{attrs:{"xs5":"","sm6":"","md6":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                          .icon,"lazy-src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                          .icon,"alt":_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                          .icon,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '21px'
                                : '17px',
                          })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third .title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }),domProps:{"innerHTML":_vm._s(
                            _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                              .description
                          )}})])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg4":"","xl2":"","pa-2":""}},[_c('v-card',{attrs:{"color":"#FBD2D2","height":"350px","elevation":"10"}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","justify-center":""}},[_c('v-flex',{attrs:{"xs5":"","sm6":"","md6":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                          .icon,"lazy-src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                          .icon,"alt":_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                          .icon,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '21px'
                                : '17px',
                          })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs .fourth.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }),domProps:{"innerHTML":_vm._s(
                            _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                              .fourth.description
                          )}})])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg4":"","xl2":"","pa-2":""}},[_c('v-card',{attrs:{"color":"#D2F1FB","height":"350px","elevation":"10"}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","justify-center":""}},[_c('v-flex',{attrs:{"xs5":"","sm6":"","md6":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                          .icon,"lazy-src":_vm.mediaURL +
                        _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                          .icon,"alt":_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                          .icon,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","px-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '21px'
                                : '17px',
                          })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth .title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
                            'font-size':
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '18px'
                                : '14px',
                          }),domProps:{"innerHTML":_vm._s(
                            _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                              .description
                          )}})])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1),(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{staticStyle:{"background-color":"#eefff2"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md7":"","align-self-center":"","text-center":""}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"#000000","letter-spacing":"1px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '28px' : '24px',
            })},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.bottomContent.title)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","md8":"","pt-4":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsmedium","color":"#000000","text-align":"justify"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
            }),domProps:{"innerHTML":_vm._s(
              _vm.storage.bgfSafetyTrainingDetails.bottomContent.description
            )}})]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","py-10":""}},[_c('v-btn',{attrs:{"color":"#3CB043","dark":"","to":'/course?id=' + _vm.$route.query.id}},[_c('span',{staticStyle:{"font-family":"opensanssemibold","text-transform":"none"}},[_vm._v("Enroll Today")])])],1),_c('v-flex',{attrs:{"xs12":"","sm10":"","md8":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"#000000","text-align":"justify"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
            }),domProps:{"innerHTML":_vm._s(
              _vm.storage.bgfSafetyTrainingDetails.bottomContent.smallDescription
            )}})])],1)],1),(_vm.storage.bgfSafetyTrainingDetails.bottomContent)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.bottomContent.image,"lazy-src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.bottomContent.image,"alt":_vm.storage.bgfSafetyTrainingDetails.bottomContent.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }